<template>
  <div>
    <div class="main-header">
      <div class="title">组织管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-row">
            <div class="search-option">
              <span class="label">组织名称</span>
              <a-input
                placeholder="请输入组织名称"
                v-model="searchName"
              ></a-input>
            </div>
            <div class="search-option">
              <span class="label">所属组织</span>
              <a-tree-select
                allowClear
                class="search-select"
                v-model="searchGroupId"
                :tree-data="groupTree"
                placeholder="请选择所属组织"
              >
              </a-tree-select>
            </div>
            <div class="empty">
              <a-button type="primary" @click="searchByOptions" icon="search"
                >搜索</a-button
              >
            </div>
            <div class="empty"></div>
            <div class="empty"></div>
            <div class="empty"></div>
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addDept">新增</a-button>
          </div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
            <template slot="type" slot-scope="type">
              <span>
                {{ allDeptType.find((item) => item.value == type).title }}</span
              >
            </template>
            <template slot="timeMoney" slot-scope="timeMoney">
              <span> {{ timeMoney }}</span>
            </template>
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="editDept(row)">编辑</a>
                <a @click="editAdmin(row)">管理员</a>
                <a @click="editStatus(row)">{{
                  row.status == 1 ? "启用" : "禁用"
                }}</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      v-model="addDeptModal"
      :title="modalName"
      :footer="null"
      :closable="false"
      :width="1000"
      centered
    >
      <a-form-model
        :model="form"
        :rules="rules"
        class="activity-form"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
        ref="form"
      >
        <a-form-model-item label="组织名称" prop="name">
          <a-input v-model="form.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="组织类型">
          <a-select v-model="form.type" :options="allDeptType"></a-select>
        </a-form-model-item>
        <a-form-model-item label="上级组织">
          <a-tree-select
            v-model="form.parentId"
            :tree-data="groupTree"
            placeholder="请选择所属组织"
          >
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item
          label="省市区"
          style="display: flex; align-items: center"
        >
          <div style="display: flex; gap: 10px">
            <a-select
              v-model="form.provinceCode"
              :options="provinceData"
              style="flex: 1"
              @change="provinceChose"
            ></a-select>
            <a-select
              v-model="form.cityCode"
              :options="cityData"
              style="flex: 1"
              @change="cityChose"
            ></a-select>
            <a-select
              v-model="form.districtCode"
              :options="districtData"
              style="flex: 1"
            ></a-select>
          </div>
        </a-form-model-item>

        <a-form-model-item label="详细地址">
          <a-input
            v-model="form.address"
            :disabled="true"
            placeholder="请在下方地图选择地点"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="时间币余额">
          <a-input-number
            v-model="form.timeMoney"
            type="number"
            :min="0"
            :formatter="limitNumber"
            :parser="limitNumber"
            style="width: 100%"
          ></a-input-number>
        </a-form-model-item>
        <div style="width: 100%; position: relative; margin-bottom: 10px">
          <div id="map-container"></div>
          <div class="search-container">
            <a-input v-model="searchKeyWord" @input="searchMap"></a-input>
            <div class="search-list" v-if="searchResult.length">
              <div
                v-for="(item, index) in searchResult"
                :key="index"
                @click="choseAddress(item)"
                class="search-item"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <div class="modal-btn">
        <a-button @click="save" type="primary" :disabled="saveDisable"
          >保存提交</a-button
        >
      </div>
    </a-modal>
    <a-modal
      v-model="editAdminModal"
      :footer="null"
      title="管理员列表"
      :closable="false"
      :width="900"
    >
      <a-table
        :data-source="groupAdminList"
        :columns="groupAdminListColumn"
        :pagination="false"
        :scroll="{ y: 220 }"
      >
      </a-table>

      <div class="modal-btn">
        <a-button icon="download" type="primary"
          >导出名单
          <vue-excel-xlsx
            class="excel-btn"
            :columns="adminListExcelColumn"
            :sheetname="adminListExcelTitle"
            :filename="adminListExcelTitle"
            :data="groupAdminList"
          >
          </vue-excel-xlsx>
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
const limitNumber = (value) => {
  if (value === 0) {
    return value;
  }
  if (value === "0") {
    return value;
  }
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(/^(0+)|[^\d]/g, "") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, "") : "";
  } else {
    return "";
  }
};
import {
  getProvince,
  getCityOrDistrict,
  getGroupList,
  addGroup,
  changeGroup,
  getGroupTree,
  getGroupAdminList,
  queryAdmin,
  linkAdmin,
  groupDisable,
} from "@/network/api";
import Pagination from "../components/Pagination";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      limitNumber,
      searchGroupId: "",
      adminListExcelTitle: "",
      groupAdminList: [],
      addDisabled: false,
      nowRowId: "",
      searchName: "",
      saveDisable: false,
      modalName: "",
      total: 0,
      adminForm: {},
      editAdminModal: false,
      provinceData: [],
      cityData: [],
      districtData: [],

      allDeptType: [
        { title: "总部", value: 0 },
        { title: "分部", value: 1 },
        { title: "社会组织", value: 2 },
        { title: "高校团体", value: 3 },
      ],
      allDept: [],
      form: {},
      addDeptModal: false,
      selectedRowList: [],
      selectedRowKeys: [],
      tableLoading: true,
      tableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
      },

      map: null,
      searchKeyWord: "",
      timer: null,
      searchResult: [],
    };
  },
  methods: {
    ...mapMutations(["setGroupTree"]),
    formatGroupTree(rawData) {
      rawData.forEach((item) => {
        item.title = item.name;
        item.key = item.id;
        item.value = item.id;
        item.children = item.childList;
        delete item.childList;
        delete item.id;
        delete item.parentId;
        delete item.name;
        if (item.children.length) {
          this.formatGroupTree(item.children);
        }
      });
    },
    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getGroupList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getGroupList(this.searchOptions);
    },
    refresh() {
      this.getGroupList();
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveDisable = true;
          if (this.modalName == "编辑组织") {
            changeGroup(this.form).then((res) => {
              if (res.status == 0) {
                this.$message.success("编辑组织成功！");
                this.getGroupList(this.searchOptions);
                this.addDeptModal = false;
                getGroupTree().then((res) => {
                  this.formatGroupTree(res.data);
                  this.setGroupTree(res.data);
                });
              } else {
                this.$message.error("编辑组织失败！");
                this.saveDisable = false;
              }
            });
          } else {
            addGroup(this.form).then((res) => {
              if (res.status == 0) {
                this.$message.success("添加组织成功！");
                this.getGroupList(this.searchOptions);
                this.addDeptModal = false;
                getGroupTree().then((res) => {
                  this.formatGroupTree(res.data);
                  this.setGroupTree(res.data);
                });
              } else {
                this.$message.error("添加组织失败！");
                this.saveDisable = false;
              }
            });
          }
        }
      });
    },

    editStatus(row) {
      this.$confirm({
        title: `确定要${row.status == 1 ? "启用" : "禁用"}该组织吗？`,
        onOk: async () => {
          const data = await groupDisable({
            groupId: row.id,
            status: row.status == 1 ? 0 : 1,
          });
          if (data.status === 0) {
            this.$message.success("操作成功");
            this.getGroupList(this.searchOptions);
          } else {
            this.$message.error(data.msg);
          }
        },
      });
    },

    edit() {
      this.editAdminModal = false;
    },

    editDept(row) {
      this.modalName = "编辑组织";
      this.form = Object.assign({}, row);
      if (row.provinceCode) {
        getCityOrDistrict({ code: row.provinceCode }).then((res) => {
          const { data } = res;
          this.cityData = data.map((item) => {
            item.value = item.code;
            item.title = item.name;
            return item;
          });
        });
      }
      if (row.cityCode) {
        getCityOrDistrict({ code: row.cityCode }).then((res) => {
          const { data } = res;
          this.districtData = data.map((item) => {
            item.value = item.code;
            item.title = item.name;
            return item;
          });
        });
      }
      this.saveDisable = false;
      this.addDeptModal = true;
      this.$nextTick(() => {
        this.initMap(row.latitude, row.longitude);
      });
    },
    editAdmin(row) {
      this.nowRowId = row.id;

      getGroupAdminList({ groupId: row.id }).then((res) => {
        console.log(res.data);
        this.groupAdminList = res.data;
        this.adminListExcelTitle = row.name + "管理员列表";
        this.editAdminModal = true;
      });
    },

    async provinceChose(value) {
      const { data } = await getCityOrDistrict({ code: value });
      this.cityData = data.map((item) => {
        item.value = item.code;
        item.title = item.name;
        return item;
      });
      this.$set(this.form, "cityCode", null);
      this.$set(this.form, "districtCode", null);
    },
    async cityChose(value) {
      const { data } = await getCityOrDistrict({ code: value });
      this.districtData = data.map((item) => {
        item.value = item.code;
        item.title = item.name;
        return item;
      });
      this.$set(this.form, "districtCode", null);
    },

    addDept() {
      this.modalName = "新增组织";
      this.form = {};
      this.saveDisable = false;
      this.addDeptModal = true;
      this.$nextTick(() => {
        this.initMap();
        this.$refs.form.clearValidate();
      });
    },
    formatSex(e) {
      if (e == 1) {
        return "男";
      }
      if (e == 0) {
        return "女";
      }
      return "未知";
    },

    searchByOptions() {
      this.searchOptions.page = 1;
      this.searchOptions.groupId = this.searchGroupId;
      this.searchOptions.name = this.searchName;
      this.getGroupList(this.searchOptions);
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.map((item) => {
        this.selectedRowList.push(this.tableData[item]);
      });
    },
    initProvince() {
      getProvince().then((res) => {
        const { data } = res;
        this.provinceData = data.map((item) => {
          item.value = item.code;
          item.title = item.name;
          return item;
        });
      });
    },
    getGroupList(
      params = {
        page: 1,
        pageSize: 20,
      }
    ) {
      this.tableLoading = true;
      getGroupList(params).then((res) => {
        if (res.status == 0) {
          var index =
            (this.searchOptions.page - 1) * this.searchOptions.pageSize + 1;
          this.tableData = res.data.content.map((item) => {
            item.index = index;
            index++;
            return item;
          });
          this.tableLoading = false;
          this.total = res.data.total;
        }
      });
    },

    choseAddress(item) {
      this.$set(
        this.form,
        "address",
        (item.province ? item.province : "") +
          (item.province == item.city ? "" : item.city ? item.city : "") +
          item.title
      );
      this.$set(this.form, "point", item.point);
      this.$set(this.form, "longitude", item.point.lng);
      this.$set(this.form, "latitude", item.point.lat);
      const marker = new BMap.Marker(item.point);
      this.map.clearOverlays();
      this.map.addOverlay(marker);
      marker.setAnimation(BMAP_ANIMATION_BOUNCE);
      this.map.centerAndZoom(item.point, 15);
      this.searchResult = [];
    },
    searchMap() {
      this.searchResult = [];
      clearTimeout(this.timer);
      this.timer = null;
      if (!this.searchKeyWord) {
        return;
      }
      this.timer = setTimeout(() => {
        const mapSearch = new BMap.LocalSearch(this.map, {
          onSearchComplete: (results) => {
            // this.searchResult = e.Kr || e.Xr;
            if (mapSearch.getStatus() == BMAP_STATUS_SUCCESS) {
              // 判断状态是否正确
              var s = [];
              for (var i = 0; i < results.getCurrentNumPois(); i++) {
                s.push(results.getPoi(i));
              }
              console.log(s);
              this.searchResult = s;
            } else {
              this.searchResult = [];
            }
          },
        });
        mapSearch.search(this.searchKeyWord);
      }, 1000);
    },
    initMap(lat, lng) {
      this.map = new BMap.Map("map-container");
      var point = new BMap.Point(116.331398, 39.897445);
      this.map.centerAndZoom(point, 12);
      this.map.addControl(new BMap.NavigationControl());

      this.map.addEventListener("click", (e) => {
        const marker = new BMap.Marker(e.point);
        // this.map.centerAndZoom(e.point, 15);
        this.map.clearOverlays();
        this.map.addOverlay(marker);
        marker.setAnimation(BMAP_ANIMATION_BOUNCE);
        const searchGeo = new BMap.Geocoder();
        searchGeo.getLocation(
          e.point,
          (x) => {
            this.$set(
              this.form,
              "address",
              x.address +
                (x.surroundingPois.length > 0 ? x.surroundingPois[0].title : "")
            );
          },
          {
            poiRadius: 100,
            numPois: 1,
          }
        );
        this.$set(this.form, "longitude", e.point.lng);
        this.$set(this.form, "latitude", e.point.lat);
      });
      if (!lat || !lng) {
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((r) => {
          if (geolocation.getStatus() == BMAP_STATUS_SUCCESS) {
            var mk = new BMap.Marker(r.point);
            this.map.addOverlay(mk);
            this.map.panTo(r.point);
          } else {
            this.$message.error("定位失败！");
          }
        });
      } else {
        var p = new BMap.Point(lng, lat);
        var mk = new BMap.Marker(p);
        this.map.addOverlay(mk);
        this.map.panTo(p);
      }
    },
  },
  created() {
    this.getGroupList();
    this.initProvince();
    getGroupList({
      page: 1,
      pageSize: 20,
    }).then((res) => {
      this.allDept = res.data.content.map((item) => {
        item.value = item.id;
        item.title = item.name;
        return item;
      });
    });
  },
  computed: {
    ...mapState(["groupTree"]),
    adminListExcelColumn() {
      return [
        { label: "姓名", field: "nickName" },
        { label: "手机号", field: "phone" },
        { label: "证件号", field: "idCard" },
      ];
    },
    groupAdminListColumn() {
      return [
        { dataIndex: "nickName", title: "姓名", align: "center" },
        { dataIndex: "phone", title: "手机号", align: "center" },
        { dataIndex: "idCard", title: "证件号", align: "center" },
      ];
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: "请输入组织名称！",
            trigger: "blur",
          },
        ],
      };
    },
    excelColumns() {
      return [
        // { label: "性别", field: "sex", dataFormat: this.formatSex },
      ];
    },
    excelData() {
      // return this.selectedRowList.length
      //   ? this.selectedRowList
      //   : this.tableData;
    },
    tableColumns() {
      return [
        { dataIndex: "index", title: "", align: "center", width: 90 },
        { dataIndex: "name", title: "组织名称", align: "center", width: 200 },
        {
          dataIndex: "type",
          title: "组织类型",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          dataIndex: "timeMoney",
          title: "时间币余额",
          align: "center",
          scopedSlots: { customRender: "timeCoin" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.admin-list {
  flex: 50%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.admin-info {
  flex: 1;
  text-align: center;
}
#map-container {
  margin-left: 20px;
  width: 400px;
  height: 250px;
}
.search-container {
  position: absolute;
  top: 10px;
  left: 220px;
  width: 200px;
}
.search-list {
  background: #fff;
  overflow-y: auto;
  height: 180px;
}
.search-item {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-item:hover {
  background: #e6f7ff;
}
</style>